.tox {
  box-shadow: none;
  box-sizing: content-box;
  color: #222f3e;
  cursor: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  vertical-align: initial;
  white-space: normal;
}
.tox *:not(svg):not(rect) {
  box-sizing: inherit;
  color: inherit;
  cursor: inherit;
  direction: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-tap-highlight-color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  text-shadow: inherit;
  text-transform: inherit;
  vertical-align: inherit;
  white-space: inherit;
}
.tox *:not(svg):not(rect) {
  /* stylelint-disable-line no-duplicate-selectors */
  background: transparent;
  border: 0;
  box-shadow: none;
  float: none;
  height: auto;
  margin: 0;
  max-width: none;
  outline: 0;
  padding: 0;
  position: static;
  width: auto;
}
.tox:not([dir=rtl]) {
  direction: ltr;
  text-align: left;
}
.tox[dir=rtl] {
  direction: rtl;
  text-align: right;
}
.tox-tinymce {
  border: 2px solid #eeeeee;
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow: hidden;
  position: relative;
  visibility: inherit !important;
}
.tox.tox-tinymce-inline {
  border: none;
  box-shadow: none;
  overflow: initial;
}
.tox.tox-tinymce-inline .tox-editor-container {
  overflow: initial;
}
.tox.tox-tinymce-inline .tox-editor-header {
  background-color: #fff;
  border: 2px solid #eeeeee;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
}
.tox-tinymce-aux {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  z-index: 1300;
}
.tox-tinymce *:focus,
.tox-tinymce-aux *:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}
.tox[dir=rtl] .tox-icon--flip svg {
  transform: rotateY(180deg);
}
.tox .accessibility-issue__header {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}
.tox .accessibility-issue__description {
  align-items: stretch;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
}
.tox .accessibility-issue__description > div {
  padding-bottom: 4px;
}
.tox .accessibility-issue__description > div > div {
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}
.tox .accessibility-issue__description > div > div .tox-icon svg {
  display: block;
}
.tox .accessibility-issue__repair {
  margin-top: 16px;
}
.tox .tox-dialog__body-content .accessibility-issue--info .accessibility-issue__description {
  background-color: rgba(0, 101, 216, 0.1);
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--info .tox-form__group h2 {
  color: #006ce7;
}
.tox .tox-dialog__body-content .accessibility-issue--info .tox-icon svg {
  fill: #006ce7;
}
.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon {
  background-color: #006ce7;
  color: #fff;
}
.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:hover,
.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:focus {
  background-color: #0060ce;
}
.tox .tox-dialog__body-content .accessibility-issue--info a.tox-button--naked.tox-button--icon:active {
  background-color: #0054b4;
}
.tox .tox-dialog__body-content .accessibility-issue--warn {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-dialog__body-content .accessibility-issue--warn .accessibility-issue__description {
  background-color: rgba(255, 165, 0, 0.08);
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--warn .tox-form__group h2 {
  color: #8f5d00;
}
.tox .tox-dialog__body-content .accessibility-issue--warn .tox-icon svg {
  fill: #8f5d00;
}
.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon {
  background-color: #FFE89D;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:hover,
.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:focus {
  background-color: #F2D574;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--warn a.tox-button--naked.tox-button--icon:active {
  background-color: #E8C657;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--error {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-dialog__body-content .accessibility-issue--error .accessibility-issue__description {
  background-color: rgba(204, 0, 0, 0.1);
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--error .tox-form__group h2 {
  color: #c00;
}
.tox .tox-dialog__body-content .accessibility-issue--error .tox-icon svg {
  fill: #c00;
}
.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon {
  background-color: #F2BFBF;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:hover,
.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:focus {
  background-color: #E9A4A4;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--error a.tox-button--naked.tox-button--icon:active {
  background-color: #EE9494;
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description {
  background-color: rgba(120, 171, 70, 0.1);
  color: #222f3e;
}
.tox .tox-dialog__body-content .accessibility-issue--success .accessibility-issue__description > *:last-child {
  display: none;
}
.tox .tox-dialog__body-content .accessibility-issue--success .tox-form__group h2 {
  color: #527530;
}
.tox .tox-dialog__body-content .accessibility-issue--success .tox-icon svg {
  fill: #527530;
}
.tox .tox-dialog__body-content .accessibility-issue__header .tox-form__group h1,
.tox .tox-dialog__body-content .tox-form__group .accessibility-issue__description h2 {
  font-size: 14px;
  margin-top: 0;
}
.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-left: 4px;
}
.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__header > *:nth-last-child(2) {
  margin-left: auto;
}
.tox:not([dir=rtl]) .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 4px 4px 8px;
}
.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__header .tox-button {
  margin-right: 4px;
}
.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__header > *:nth-last-child(2) {
  margin-right: auto;
}
.tox[dir=rtl] .tox-dialog__body-content .accessibility-issue__description {
  padding: 4px 8px 4px 4px;
}
.tox .mce-codemirror {
  background: #fff;
  bottom: 0;
  font-size: 13px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.tox .mce-codemirror.tox-inline-codemirror {
  margin: 8px;
  position: absolute;
}
.tox .tox-advtemplate .tox-form__grid {
  flex: 1;
}
.tox .tox-advtemplate .tox-form__grid > div:first-child {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.tox .tox-advtemplate .tox-form__grid > div:first-child > div:nth-child(2) {
  flex-basis: 0;
  flex-grow: 1;
  overflow: auto;
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox .tox-advtemplate .tox-form__grid > div:first-child {
    width: 100%;
  }
}
.tox .tox-advtemplate iframe {
  border-color: #eeeeee;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  margin: 0 10px;
}
.tox .tox-anchorbar {
  display: flex;
  flex: 0 0 auto;
}
.tox .tox-bottom-anchorbar {
  display: flex;
  flex: 0 0 auto;
}
.tox .tox-bar {
  display: flex;
  flex: 0 0 auto;
}
.tox .tox-button {
  background-color: #006ce7;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: #006ce7;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 4px 16px;
  position: relative;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
}
.tox .tox-button::before {
  border-radius: 6px;
  bottom: -1px;
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #006ce7;
  content: '';
  left: -1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
}
.tox .tox-button[disabled] {
  background-color: #006ce7;
  background-image: none;
  border-color: #006ce7;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}
.tox .tox-button:focus:not(:disabled) {
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button:focus:not(:disabled)::before {
  opacity: 1;
}
.tox .tox-button:hover:not(:disabled) {
  background-color: #0060ce;
  background-image: none;
  border-color: #0060ce;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button:active:not(:disabled) {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button.tox-button--enabled {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button.tox-button--enabled[disabled] {
  background-color: #0054b4;
  background-image: none;
  border-color: #0054b4;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}
.tox .tox-button.tox-button--enabled:focus:not(:disabled) {
  background-color: #00489b;
  background-image: none;
  border-color: #00489b;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button.tox-button--enabled:hover:not(:disabled) {
  background-color: #00489b;
  background-image: none;
  border-color: #00489b;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button.tox-button--enabled:active:not(:disabled) {
  background-color: #003c81;
  background-image: none;
  border-color: #003c81;
  box-shadow: none;
  color: #fff;
}
.tox .tox-button--icon-and-text,
.tox .tox-button.tox-button--icon-and-text,
.tox .tox-button.tox-button--secondary.tox-button--icon-and-text {
  display: flex;
  padding: 5px 4px;
}
.tox .tox-button--icon-and-text .tox-icon svg,
.tox .tox-button.tox-button--icon-and-text .tox-icon svg,
.tox .tox-button.tox-button--secondary.tox-button--icon-and-text .tox-icon svg {
  display: block;
  fill: currentColor;
}
.tox .tox-button--secondary {
  background-color: #f0f0f0;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: #f0f0f0;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  outline: none;
  padding: 4px 16px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: none;
}
.tox .tox-button--secondary[disabled] {
  background-color: #f0f0f0;
  background-image: none;
  border-color: #f0f0f0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-button--secondary:focus:not(:disabled) {
  background-color: #e3e3e3;
  background-image: none;
  border-color: #e3e3e3;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary:hover:not(:disabled) {
  background-color: #e3e3e3;
  background-image: none;
  border-color: #e3e3e3;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary:active:not(:disabled) {
  background-color: #d6d6d6;
  background-image: none;
  border-color: #d6d6d6;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary.tox-button--enabled {
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary.tox-button--enabled[disabled] {
  background-color: #a8c8ed;
  background-image: none;
  border-color: #a8c8ed;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-button--secondary.tox-button--enabled:focus:not(:disabled) {
  background-color: #93bbe9;
  background-image: none;
  border-color: #93bbe9;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary.tox-button--enabled:hover:not(:disabled) {
  background-color: #93bbe9;
  background-image: none;
  border-color: #93bbe9;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--secondary.tox-button--enabled:active:not(:disabled) {
  background-color: #7daee4;
  background-image: none;
  border-color: #7daee4;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-button--icon,
.tox .tox-button.tox-button--icon,
.tox .tox-button.tox-button--secondary.tox-button--icon {
  padding: 4px;
}
.tox .tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--icon .tox-icon svg,
.tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg {
  display: block;
  fill: currentColor;
}
.tox .tox-button-link {
  background: 0;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.tox .tox-button-link--sm {
  font-size: 14px;
}
.tox .tox-button--naked {
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}
.tox .tox-button--naked[disabled] {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: rgba(34, 47, 62, 0.5);
}
.tox .tox-button--naked:hover:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}
.tox .tox-button--naked:focus:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.12);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}
.tox .tox-button--naked:active:not(:disabled) {
  background-color: rgba(34, 47, 62, 0.18);
  border-color: transparent;
  box-shadow: unset;
  color: #222f3e;
}
.tox .tox-button--naked .tox-icon svg {
  fill: currentColor;
}
.tox .tox-button--naked.tox-button--icon:hover:not(:disabled) {
  color: #222f3e;
}
.tox .tox-checkbox {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 36px;
  min-width: 36px;
}
.tox .tox-checkbox__input {
  /* Hide from view but visible to screen readers */
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
.tox .tox-checkbox__icons {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 0 0 2px transparent;
  box-sizing: content-box;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: calc(4px - 1px);
  width: 24px;
}
.tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: block;
  fill: rgba(34, 47, 62, 0.3);
}
@media (forced-colors: active) {
  .tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
    fill: currentColor !important;
  }
}
.tox .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: none;
  fill: #006ce7;
}
.tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: none;
  fill: #006ce7;
}
.tox .tox-checkbox--disabled {
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-checkbox--disabled .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}
.tox input.tox-checkbox__input:checked + .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  display: block;
}
.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
  display: none;
}
.tox input.tox-checkbox__input:indeterminate + .tox-checkbox__icons .tox-checkbox-icon__indeterminate svg {
  display: block;
}
.tox input.tox-checkbox__input:focus + .tox-checkbox__icons {
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px #006ce7;
  padding: calc(4px - 1px);
}
.tox:not([dir=rtl]) .tox-checkbox__label {
  margin-left: 4px;
}
.tox:not([dir=rtl]) .tox-checkbox__input {
  left: -10000px;
}
.tox:not([dir=rtl]) .tox-bar .tox-checkbox {
  margin-left: 4px;
}
.tox[dir=rtl] .tox-checkbox__label {
  margin-right: 4px;
}
.tox[dir=rtl] .tox-checkbox__input {
  right: -10000px;
}
.tox[dir=rtl] .tox-bar .tox-checkbox {
  margin-right: 4px;
}
.tox {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-collection--toolbar .tox-collection__group {
  display: flex;
  padding: 0;
}
.tox .tox-collection--grid .tox-collection__group {
  display: flex;
  flex-wrap: wrap;
  max-height: 208px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}
.tox .tox-collection--list .tox-collection__group {
  border-bottom-width: 0;
  border-color: #e3e3e3;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-top-width: 1px;
  padding: 4px 0;
}
.tox .tox-collection--list .tox-collection__group:first-child {
  border-top-width: 0;
}
.tox .tox-collection__group-heading {
  background-color: #fcfcfc;
  color: rgba(34, 47, 62, 0.7);
  cursor: default;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 4px;
  margin-top: -4px;
  padding: 4px 8px;
  text-transform: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}
.tox .tox-collection__item {
  align-items: center;
  border-radius: 3px;
  color: #222f3e;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}
.tox .tox-collection--list .tox-collection__item {
  padding: 4px 8px;
}
.tox .tox-collection--toolbar .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}
.tox .tox-collection--grid .tox-collection__item {
  border-radius: 3px;
  padding: 4px;
}
.tox .tox-collection--list .tox-collection__item--enabled:not(.tox-collection__item--state-disabled) {
  background-color: #fff;
  color: #222f3e;
}
.tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: #006ce7;
  color: #fff;
}
@media (forced-colors: active) {
  .tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    border: solid 1px;
  }
}
.tox .tox-collection--grid .tox-collection__item--enabled:not(.tox-collection__item--state-disabled) {
  background-color: #a6ccf7;
  color: #222f3e;
}
.tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  background-color: #f0f0f0;
  color: #222f3e;
  position: relative;
  z-index: 1;
}
.tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled):focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 inset;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-collection--grid .tox-collection__item--active:not(.tox-collection__item--state-disabled):focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-collection--toolbar .tox-collection__item--enabled:not(.tox-collection__item--state-disabled) {
  background-color: #a6ccf7;
  color: #222f3e;
}
@media (forced-colors: active) {
  .tox .tox-collection--toolbar .tox-collection__item--enabled:not(.tox-collection__item--state-disabled) {
    border-radius: 3px;
    outline: solid 1px;
  }
}
.tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
  color: #222f3e;
  background-color: #fff;
  position: relative;
}
.tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled):hover {
  background-color: #f0f0f0;
  color: #222f3e;
}
.tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled):focus {
  background-color: #f0f0f0;
  color: #222f3e;
}
.tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled):focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled):focus::after {
    border: 2px solid highlight;
  }
}
@media (forced-colors: active) {
  .tox .tox-collection--toolbar .tox-collection__item--active:not(.tox-collection__item--state-disabled):hover {
    border-radius: 3px;
    outline: solid 1px;
  }
}
.tox .tox-collection__item-icon,
.tox .tox-collection__item-checkmark {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.tox .tox-collection__item-icon svg,
.tox .tox-collection__item-checkmark svg {
  fill: currentColor;
}
.tox .tox-collection--toolbar-lg .tox-collection__item-icon {
  height: 48px;
  width: 48px;
}
.tox .tox-collection__item-label {
  color: currentColor;
  display: inline-block;
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  max-width: 100%;
  text-transform: none;
  word-break: break-all;
}
.tox .tox-collection__item-accessory {
  color: currentColor;
  display: inline-block;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  text-transform: none;
}
.tox .tox-collection__item-caret {
  align-items: center;
  display: flex;
  min-height: 24px;
}
.tox .tox-collection__item-caret::after {
  content: '';
  font-size: 0;
  min-height: inherit;
}
.tox .tox-collection__item-caret svg {
  fill: currentColor;
}
.tox .tox-collection__item--state-disabled {
  background-color: transparent;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-collection__item--state-disabled .tox-collection__item-caret svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-checkmark svg {
  display: none;
}
.tox .tox-collection--list .tox-collection__item:not(.tox-collection__item--enabled) .tox-collection__item-accessory + .tox-collection__item-checkmark {
  display: none;
}
.tox .tox-collection--horizontal {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: nowrap;
  margin-bottom: 0;
  overflow-x: auto;
  padding: 0;
}
.tox .tox-collection--horizontal .tox-collection__group {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 4px;
}
.tox .tox-collection--horizontal .tox-collection__item {
  height: 28px;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
}
.tox .tox-collection--horizontal .tox-collection__item-label {
  white-space: nowrap;
}
.tox .tox-collection--horizontal .tox-collection__item-caret {
  margin-left: 4px;
}
.tox .tox-collection__item-container {
  display: flex;
}
.tox .tox-collection__item-container--row {
  align-items: center;
  flex: 1 1 auto;
  flex-direction: row;
}
.tox .tox-collection__item-container--row.tox-collection__item-container--align-left {
  margin-right: auto;
}
.tox .tox-collection__item-container--row.tox-collection__item-container--align-right {
  justify-content: flex-end;
  margin-left: auto;
}
.tox .tox-collection__item-container--row.tox-collection__item-container--valign-top {
  align-items: flex-start;
  margin-bottom: auto;
}
.tox .tox-collection__item-container--row.tox-collection__item-container--valign-middle {
  align-items: center;
}
.tox .tox-collection__item-container--row.tox-collection__item-container--valign-bottom {
  align-items: flex-end;
  margin-top: auto;
}
.tox .tox-collection__item-container--column {
  align-self: center;
  flex: 1 1 auto;
  flex-direction: column;
}
.tox .tox-collection__item-container--column.tox-collection__item-container--align-left {
  align-items: flex-start;
}
.tox .tox-collection__item-container--column.tox-collection__item-container--align-right {
  align-items: flex-end;
}
.tox .tox-collection__item-container--column.tox-collection__item-container--valign-top {
  align-self: flex-start;
}
.tox .tox-collection__item-container--column.tox-collection__item-container--valign-middle {
  align-self: center;
}
.tox .tox-collection__item-container--column.tox-collection__item-container--valign-bottom {
  align-self: flex-end;
}
.tox:not([dir=rtl]) .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-right: 1px solid transparent;
}
.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item > *:not(:first-child) {
  margin-left: 8px;
}
.tox:not([dir=rtl]) .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-left: 4px;
}
.tox:not([dir=rtl]) .tox-collection__item-accessory {
  margin-left: 16px;
  text-align: right;
}
.tox:not([dir=rtl]) .tox-collection .tox-collection__item-caret {
  margin-left: 16px;
}
.tox[dir=rtl] .tox-collection--horizontal .tox-collection__group:not(:last-of-type) {
  border-left: 1px solid transparent;
}
.tox[dir=rtl] .tox-collection--list .tox-collection__item > *:not(:first-child) {
  margin-right: 8px;
}
.tox[dir=rtl] .tox-collection--list .tox-collection__item > .tox-collection__item-label:first-child {
  margin-right: 4px;
}
.tox[dir=rtl] .tox-collection__item-accessory {
  margin-right: 16px;
  text-align: left;
}
.tox[dir=rtl] .tox-collection .tox-collection__item-caret {
  margin-right: 16px;
  transform: rotateY(180deg);
}
.tox[dir=rtl] .tox-collection--horizontal .tox-collection__item-caret {
  margin-right: 4px;
}
@media (forced-colors: active) {
  .tox .tox-hue-slider,
  .tox .tox-rgb-form .tox-rgba-preview {
    background-color: currentColor !important;
    border: 1px solid highlight !important;
    forced-color-adjust: none;
  }
}
.tox .tox-color-picker-container {
  display: flex;
  flex-direction: row;
  height: 225px;
  margin: 0;
}
.tox .tox-sv-palette {
  box-sizing: border-box;
  display: flex;
  height: 100%;
}
.tox .tox-sv-palette-spectrum {
  height: 100%;
}
.tox .tox-sv-palette,
.tox .tox-sv-palette-spectrum {
  width: 225px;
}
.tox .tox-sv-palette-thumb {
  background: none;
  border: 1px solid black;
  border-radius: 50%;
  box-sizing: content-box;
  height: 12px;
  position: absolute;
  width: 12px;
}
.tox .tox-sv-palette-inner-thumb {
  border: 1px solid white;
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
}
.tox .tox-hue-slider {
  box-sizing: border-box;
  height: 100%;
  width: 25px;
}
.tox .tox-hue-slider-spectrum {
  background: linear-gradient(to bottom, #f00, #ff0080, #f0f, #8000ff, #00f, #0080ff, #0ff, #00ff80, #0f0, #80ff00, #ff0, #ff8000, #f00);
  height: 100%;
  width: 100%;
}
.tox .tox-hue-slider,
.tox .tox-hue-slider-spectrum {
  width: 20px;
}
.tox .tox-hue-slider-spectrum:focus,
.tox .tox-sv-palette-spectrum:focus {
  outline: #08f solid;
}
.tox .tox-hue-slider-thumb {
  background: white;
  border: 1px solid black;
  box-sizing: content-box;
  height: 4px;
  width: 100%;
}
.tox .tox-rgb-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tox .tox-rgb-form div {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: inherit;
}
.tox .tox-rgb-form input {
  min-width: 6em;
}
.tox .tox-rgb-form input.tox-invalid {
  /* Need !important to override Chrome's focus styling unfortunately */
  border: 1px solid #f00 !important;
  box-shadow: 0 0 0 1px #f00;
}
.tox .tox-rgb-container {
  position: relative;
}
.tox .tox-rgb-form .tox-invalid-icon {
  align-content: center;
  align-items: center;
  display: none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
.tox .tox-rgb-form .tox-invalid-icon .tox-control-wrap__status-icon-invalid {
  margin: 0;
}
.tox .tox-rgb-warning-note {
  background-color: #f5cccc;
  border: 1px solid #f0b3b3;
  border-radius: 3px;
  color: #77181f;
  padding: 3px;
}
.tox input.tox-invalid + .tox-invalid-icon {
  display: flex;
}
.tox .tox-rgb-form .tox-rgba-preview {
  border: 1px solid black;
  flex-grow: 2;
  margin-bottom: 0;
}
.tox:not([dir=rtl]) .tox-sv-palette {
  margin-right: 15px;
}
.tox:not([dir=rtl]) .tox-hue-slider {
  margin-right: 15px;
}
.tox:not([dir=rtl]) .tox-hue-slider-thumb {
  margin-left: -1px;
}
.tox:not([dir=rtl]) .tox-rgb-form label {
  margin-right: 0.5em;
}
.tox[dir=rtl] .tox-sv-palette {
  margin-left: 15px;
}
.tox[dir=rtl] .tox-hue-slider {
  margin-left: 15px;
}
.tox[dir=rtl] .tox-hue-slider-thumb {
  margin-right: -1px;
}
.tox[dir=rtl] .tox-rgb-form label {
  margin-left: 0.5em;
}
.tox .tox-toolbar .tox-swatches,
.tox .tox-toolbar__primary .tox-swatches,
.tox .tox-toolbar__overflow .tox-swatches {
  margin: 5px 0 6px 11px;
}
.tox .tox-collection--list .tox-collection__group .tox-swatches-menu {
  border: 0;
  margin: -4px -4px;
}
.tox .tox-swatches__row {
  display: flex;
}
@media (forced-colors: active) {
  .tox .tox-swatches__row {
    forced-color-adjust: none;
  }
}
.tox .tox-swatch {
  height: 30px;
  transition: transform 0.15s, box-shadow 0.15s;
  width: 30px;
}
.tox .tox-swatch:hover,
.tox .tox-swatch:focus {
  box-shadow: 0 0 0 1px rgba(127, 127, 127, 0.3) inset;
  transform: scale(0.8);
}
.tox .tox-swatch--remove {
  align-items: center;
  display: flex;
  justify-content: center;
}
.tox .tox-swatch--remove svg path {
  stroke: #e74c3c;
}
.tox .tox-swatches__picker-btn {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 30px;
}
.tox .tox-swatches__picker-btn svg {
  fill: #222f3e;
  height: 24px;
  width: 24px;
}
.tox .tox-swatches__picker-btn:hover {
  background: #f0f0f0;
}
.tox div.tox-swatch:not(.tox-swatch--remove) svg {
  display: none;
  fill: #222f3e;
  height: 24px;
  margin: calc((30px - 24px) / 2) calc((30px - 24px) / 2);
  width: 24px;
}
.tox div.tox-swatch:not(.tox-swatch--remove) svg path {
  fill: #fff;
  paint-order: stroke;
  stroke: #222f3e;
  stroke-width: 2px;
}
.tox div.tox-swatch:not(.tox-swatch--remove).tox-collection__item--enabled svg {
  display: block;
}
.tox:not([dir=rtl]) .tox-swatches__picker-btn {
  margin-left: auto;
}
.tox[dir=rtl] .tox-swatches__picker-btn {
  margin-right: auto;
}
.tox {
  /* Animation for deleting a comment */
  /* A comment fades to 0 when it is being deleted, then is removed */
}
.tox .tox-conversations {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  /* This is to give the sidebar a consistent width. Need a solution for this */
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
.tox .tox-conversations__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 8px 0px rgba(34, 47, 62, 0.1);
  padding: 8px 12px;
  background: #fff;
}
.tox .tox-conversations__title {
  font-size: 20px;
  font-weight: 400;
  padding: 8px 0 8px 0;
  color: #222f3e;
  line-height: 28px;
}
.tox .tox-comment {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 8px 8px 16px 8px;
  position: relative;
}
.tox .tox-comment:hover {
  box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
}
.tox .tox-comment.tox-comment--selected {
  background-color: #fff2c9;
  border: 1px solid #ffcf30;
  box-shadow: 0px 4px 8px 0px rgba(34, 47, 62, 0.1);
}
.tox .tox-comment.tox-comment--selected:focus {
  border: 2px solid #006ce7;
  margin: -1px;
}
.tox .tox-comment.tox-comment--selected:focus:not(:first-child) {
  margin-top: 7px;
}
.tox .tox-comment.tox-comment--selected .tox-comment__single {
  margin-bottom: 12px;
}
.tox .tox-comment.tox-comment--selected .tox-comment__single:focus {
  position: relative;
}
.tox .tox-comment.tox-comment--selected .tox-comment__single:focus::after {
  top: -9px;
  pointer-events: none;
  position: absolute;
  content: "";
  display: block;
  bottom: -9px;
  left: -9px;
  right: -9px;
  border-radius: 6px;
  border: 2px solid #006ce7;
}
.tox .tox-comment__header {
  align-items: center;
  color: #222f3e;
  display: flex;
  justify-content: space-between;
}
.tox .tox-comment__date {
  color: #222f3e;
  font-size: 12px;
  line-height: 18px;
}
.tox .tox-comment__body {
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
  margin-top: 8px;
  position: relative;
  text-transform: initial;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  white-space: pre-wrap;
  /* We remove the transitions when wanting to measure and jump straight to collapsed or expanded */
}
.tox .tox-comment__body.tox-comment__body--expanded {
  max-height: 100em;
  transition: max-height 1s ease-in-out;
}
.tox .tox-comment__body.tox-comment__body--pending {
  transition: max-height 0s;
}
.tox .tox-comment__body p {
  margin: 0;
}
.tox .tox-comment__body textarea {
  resize: none;
  white-space: normal;
  width: 100%;
}
.tox .tox-comment__loading-text {
  align-items: center;
  color: #222f3e;
  display: flex;
  flex-direction: column;
  position: relative;
}
.tox .tox-comment__loading-text > div {
  padding-bottom: 16px;
}
.tox .tox-comment__overlaytext {
  bottom: 0;
  flex-direction: column;
  font-size: 14px;
  left: 0;
  padding: 1em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.tox .tox-comment__overlaytext p {
  background-color: #fff;
  box-shadow: 0 0 8px 8px #fff;
  color: #222f3e;
  text-align: center;
}
.tox .tox-comment__overlaytext div:nth-of-type(2) {
  font-size: 0.8em;
}
.tox .tox-comment__expander {
  padding-top: 8px;
}
.tox .tox-comment__expander p {
  color: rgba(34, 47, 62, 0.7);
  font-size: 14px;
  font-style: normal;
  /* Need a focus highlight on the show more/less button */
}
.tox .tox-comment__expander p:focus {
  font-weight: bold;
}
.tox .tox-comment__buttonspacing {
  padding-top: 16px;
  text-align: center;
}
.tox .tox-tbtn.tox-comment__mention-btn {
  display: flex;
  width: 34px;
  height: 34px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: transparent;
}
.tox .tox-comment-thread {
  background: #fff;
  position: relative;
  border-radius: 6px;
  background-color: transparent;
}
.tox .tox-comment-thread > *:not(:first-child) {
  margin-top: 8px;
}
.tox .tox-comment-thread__overlay::after {
  background: #fff;
  bottom: 0;
  content: "";
  display: flex;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}
.tox .tox-comment__reply {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 8px;
}
.tox .tox-comment__reply > *:first-child {
  margin-bottom: 8px;
  width: 100%;
}
.tox .tox-comment__edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 8px;
}
.tox .tox-comment__gradient::after {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  bottom: 0;
  content: "";
  display: block;
  height: 5em;
  margin-top: -40px;
  position: absolute;
  width: 100%;
}
.tox .tox-comment__overlay {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 5;
}
.tox .tox-comment__busy-spinner {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
}
.tox .tox-comment__scroll {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: auto;
  padding: 12px;
  scroll-behavior: smooth;
}
.tox .tox-comment--disappearing {
  transition: opacity 0.5s ease;
}
.tox .tox-comment[data-transitioning-destination="deleting"] {
  opacity: 0;
}
.tox .tox-skeleton .tox-skeleton__line {
  height: 16px;
  width: 100%;
  background: linear-gradient(to right, rgba(240, 240, 240, 0.5) 8%, rgba(240, 240, 240, 0.7) 18%, rgba(240, 240, 240, 0.5) 33%);
  animation: wave 2s infinite ease-out;
}
.tox .tox-skeleton .tox-skeleton__circle {
  height: 36px;
  width: 36px;
  margin-right: 8px;
  border-radius: 100%;
  background: linear-gradient(to right, rgba(240, 240, 240, 0.5) 8%, rgba(240, 240, 240, 0.7) 18%, rgba(240, 240, 240, 0.5) 33%);
  animation: wave 2s infinite ease-out;
}
@keyframes wave {
  0% {
    background-position: -268px 0;
  }
  100% {
    background-position: 268px 0;
  }
}
.tox .tox-ring-loader {
  width: 10px;
  height: 10px;
  border: 1px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tox:not([dir=rtl]) .tox-comment__edit {
  margin-left: 8px;
}
.tox:not([dir=rtl]) .tox-comment__buttonspacing > *:last-child,
.tox:not([dir=rtl]) .tox-comment__edit > *:last-child,
.tox:not([dir=rtl]) .tox-comment__reply > *:last-child {
  margin-left: 8px;
}
.tox[dir=rtl] .tox-comment__edit {
  margin-right: 8px;
}
.tox[dir=rtl] .tox-comment__buttonspacing > *:last-child,
.tox[dir=rtl] .tox-comment__edit > *:last-child,
.tox[dir=rtl] .tox-comment__reply > *:last-child {
  margin-right: 8px;
}
.tox .tox-user {
  align-items: center;
  display: flex;
}
.tox .tox-user__avatar svg {
  fill: rgba(34, 47, 62, 0.7);
}
.tox .tox-user__avatar img {
  border-radius: 50%;
  height: 36px;
  object-fit: cover;
  vertical-align: middle;
  width: 36px;
}
.tox .tox-user__name {
  color: #222f3e;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  text-transform: none;
}
.tox:not([dir=rtl]) .tox-user__avatar svg,
.tox:not([dir=rtl]) .tox-user__avatar img {
  margin-right: 8px;
}
.tox:not([dir=rtl]) .tox-user__avatar + .tox-user__name {
  margin-left: 8px;
}
.tox[dir=rtl] .tox-user__avatar svg,
.tox[dir=rtl] .tox-user__avatar img {
  margin-left: 8px;
}
.tox[dir=rtl] .tox-user__avatar + .tox-user__name {
  margin-right: 8px;
}
.tox {
  /* .tox-dialog-blocker-wrap {
    //
  } */
}
.tox .tox-dialog-wrap {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1100;
}
.tox .tox-dialog-wrap__backdrop {
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.tox .tox-dialog-wrap__backdrop--opaque {
  background-color: #fff;
}
.tox .tox-dialog {
  background-color: #fff;
  border-color: #eeeeee;
  border-radius: 10px;
  border-style: solid;
  border-width: 0;
  box-shadow: 0 16px 16px -10px rgba(34, 47, 62, 0.15), 0 0 40px 1px rgba(34, 47, 62, 0.15);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 480px;
  overflow: hidden;
  position: relative;
  width: 95vw;
  z-index: 2;
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox .tox-dialog {
    align-self: flex-start;
    margin: 8px auto;
    max-height: calc(100vh - 8px * 2);
    width: calc(100vw - 16px);
  }
}
.tox .tox-dialog-inline {
  z-index: 1100;
}
.tox .tox-dialog__header {
  align-items: center;
  background-color: #fff;
  border-bottom: none;
  color: #222f3e;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 8px 16px 0 16px;
  position: relative;
}
.tox .tox-dialog__header .tox-button {
  z-index: 1;
}
.tox .tox-dialog__draghandle {
  cursor: grab;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.tox .tox-dialog__draghandle:active {
  cursor: grabbing;
}
.tox .tox-dialog__dismiss {
  margin-left: auto;
}
.tox .tox-dialog__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
  margin: 0;
  text-transform: none;
}
.tox .tox-dialog__body {
  color: #222f3e;
  display: flex;
  flex: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
  min-width: 0;
  text-align: left;
  text-transform: none;
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body {
    flex-direction: column;
  }
}
.tox .tox-dialog__body-nav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 16px 16px;
}
@media only screen and (min-width: 768px ) {
  .tox .tox-dialog__body-nav {
    max-width: 11em;
  }
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox .tox-dialog__body-nav {
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    padding-bottom: 0;
  }
}
.tox .tox-dialog__body-nav-item {
  border-bottom: 2px solid transparent;
  color: rgba(34, 47, 62, 0.7);
  display: inline-block;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
  max-width: 13em;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.tox .tox-dialog__body-nav-item:focus {
  background-color: rgba(0, 108, 231, 0.1);
}
.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid #006ce7;
  color: #006ce7;
}
@media (forced-colors: active) {
  .tox .tox-dialog__body-nav-item--active {
    border-bottom: 2px solid highlight;
    color: highlight;
  }
}
.tox .tox-dialog__body-content {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: min(650px, calc(100vh - 110px));
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 16px 16px;
}
.tox .tox-dialog__body-content > * {
  margin-bottom: 0;
  margin-top: 16px;
}
.tox .tox-dialog__body-content > *:first-child {
  margin-top: 0;
}
.tox .tox-dialog__body-content > *:last-child {
  margin-bottom: 0;
}
.tox .tox-dialog__body-content > *:only-child {
  margin-bottom: 0;
  margin-top: 0;
}
.tox .tox-dialog__body-content a {
  color: #006ce7;
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-dialog__body-content a:hover,
.tox .tox-dialog__body-content a:focus {
  color: #003c81;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-dialog__body-content a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #006ce7;
  outline-offset: 2px;
}
.tox .tox-dialog__body-content a:active {
  color: #00244e;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-dialog__body-content svg {
  fill: #222f3e;
}
.tox .tox-dialog__body-content strong {
  font-weight: bold;
}
.tox .tox-dialog__body-content ul {
  list-style-type: disc;
}
.tox .tox-dialog__body-content ul,
.tox .tox-dialog__body-content ol,
.tox .tox-dialog__body-content dd {
  padding-inline-start: 2.5rem;
}
.tox .tox-dialog__body-content ul,
.tox .tox-dialog__body-content ol,
.tox .tox-dialog__body-content dl {
  margin-bottom: 16px;
}
.tox .tox-dialog__body-content ul,
.tox .tox-dialog__body-content ol,
.tox .tox-dialog__body-content dl,
.tox .tox-dialog__body-content dd,
.tox .tox-dialog__body-content dt {
  display: block;
  margin-inline-end: 0;
  margin-inline-start: 0;
}
.tox .tox-dialog__body-content .tox-form__group h1 {
  color: #222f3e;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}
.tox .tox-dialog__body-content .tox-form__group h2 {
  color: #222f3e;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  margin-bottom: 16px;
  margin-top: 2rem;
  text-transform: none;
}
.tox .tox-dialog__body-content .tox-form__group p {
  margin-bottom: 16px;
}
.tox .tox-dialog__body-content .tox-form__group h1:first-child,
.tox .tox-dialog__body-content .tox-form__group h2:first-child,
.tox .tox-dialog__body-content .tox-form__group p:first-child {
  margin-top: 0;
}
.tox .tox-dialog__body-content .tox-form__group h1:last-child,
.tox .tox-dialog__body-content .tox-form__group h2:last-child,
.tox .tox-dialog__body-content .tox-form__group p:last-child {
  margin-bottom: 0;
}
.tox .tox-dialog__body-content .tox-form__group h1:only-child,
.tox .tox-dialog__body-content .tox-form__group h2:only-child,
.tox .tox-dialog__body-content .tox-form__group p:only-child {
  margin-bottom: 0;
  margin-top: 0;
}
.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--center {
  text-align: center;
}
.tox .tox-dialog__body-content .tox-form__group .tox-label.tox-label--end {
  text-align: end;
}
.tox .tox-dialog--width-lg {
  height: 650px;
  max-width: 1200px;
}
.tox .tox-dialog--fullscreen {
  height: 100%;
  max-width: 100%;
}
.tox .tox-dialog--fullscreen .tox-dialog__body-content {
  max-height: 100%;
}
.tox .tox-dialog--width-md {
  max-width: 800px;
}
.tox .tox-dialog--width-md .tox-dialog__body-content {
  overflow: auto;
}
.tox .tox-dialog__body-content--centered {
  text-align: center;
}
.tox .tox-dialog__footer {
  align-items: center;
  background-color: #fff;
  border-top: none;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}
.tox .tox-dialog__footer-start,
.tox .tox-dialog__footer-end {
  display: flex;
}
.tox .tox-dialog__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.tox .tox-dialog__table {
  border-collapse: collapse;
  width: 100%;
}
.tox .tox-dialog__table thead th {
  font-weight: bold;
  padding-bottom: 8px;
}
.tox .tox-dialog__table thead th:first-child {
  padding-right: 8px;
}
.tox .tox-dialog__table tbody tr {
  border-bottom: 1px solid #626262;
}
.tox .tox-dialog__table tbody tr:last-child {
  border-bottom: none;
}
.tox .tox-dialog__table td {
  padding-bottom: 8px;
  padding-top: 8px;
}
.tox .tox-dialog__table td:first-child {
  padding-right: 8px;
}
.tox .tox-dialog__iframe {
  min-height: 200px;
}
.tox .tox-dialog__iframe.tox-dialog__iframe--opaque {
  background: #fff;
}
.tox .tox-navobj-bordered {
  position: relative;
}
.tox .tox-navobj-bordered::before {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  content: '';
  inset: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}
.tox .tox-navobj-bordered iframe {
  border-radius: 6px;
}
.tox .tox-navobj-bordered-focus.tox-navobj-bordered::before {
  border-color: #006ce7;
  box-shadow: 0 0 0 1px #006ce7;
  outline: none;
}
.tox .tox-dialog__popups {
  position: absolute;
  width: 100%;
  z-index: 1100;
}
.tox .tox-dialog__body-iframe {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.tox .tox-dialog__body-iframe .tox-navobj {
  display: flex;
  flex: 1;
}
.tox .tox-dialog__body-iframe .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}
.tox .tox-dialog-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}
.tox .tox-dialog-dock-fadein {
  opacity: 1;
  visibility: visible;
}
.tox .tox-dialog-dock-transition {
  transition: visibility 0s linear 0.3s, opacity 0.3s ease;
}
.tox .tox-dialog-dock-transition.tox-dialog-dock-fadein {
  transition-delay: 0s;
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox:not([dir=rtl]) .tox-dialog__body-nav {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox:not([dir=rtl]) .tox-dialog__body-nav-item:not(:first-child) {
    margin-left: 8px;
  }
}
.tox:not([dir=rtl]) .tox-dialog__footer .tox-dialog__footer-start > *,
.tox:not([dir=rtl]) .tox-dialog__footer .tox-dialog__footer-end > * {
  margin-left: 8px;
}
.tox[dir=rtl] .tox-dialog__body {
  text-align: right;
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox[dir=rtl] .tox-dialog__body-nav {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px ) {
  body:not(.tox-force-desktop) .tox[dir=rtl] .tox-dialog__body-nav-item:not(:first-child) {
    margin-right: 8px;
  }
}
.tox[dir=rtl] .tox-dialog__footer .tox-dialog__footer-start > *,
.tox[dir=rtl] .tox-dialog__footer .tox-dialog__footer-end > * {
  margin-right: 8px;
}
body.tox-dialog__disable-scroll {
  overflow: hidden;
}
.tox .tox-dropzone-container {
  display: flex;
  flex: 1;
}
.tox .tox-dropzone {
  align-items: center;
  background: #fff;
  border: 2px dashed #eeeeee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 100px;
  padding: 10px;
}
.tox .tox-dropzone p {
  color: rgba(34, 47, 62, 0.7);
  margin: 0 0 16px 0;
}
.tox .tox-edit-area {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.tox .tox-edit-area::before {
  border: 2px solid #006ce7;
  border-radius: 4px;
  content: '';
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.15s;
  z-index: 1;
}
@media (forced-colors: active) {
  .tox .tox-edit-area::before {
    border: 2px solid highlight;
  }
}
.tox .tox-edit-area__iframe {
  background-color: #fff;
  border: 0;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}
.tox.tox-edit-focus .tox-edit-area::before {
  opacity: 1;
}
.tox.tox-inline-edit-area {
  border: 1px dotted #eeeeee;
}
.tox .tox-editor-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}
.tox .tox-editor-header {
  display: grid;
  grid-template-columns: 1fr min-content;
  z-index: 2;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background-color: #fff;
  border-bottom: none;
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.1), 0 8px 8px -4px rgba(34, 47, 62, 0.07);
  padding: 4px 0;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
  transition: box-shadow 0.5s;
}
.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-bottom .tox-editor-header {
  border-top: 1px solid #e3e3e3;
  box-shadow: none;
}
.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on .tox-editor-header {
  background-color: #fff;
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.2), 0 8px 8px -4px rgba(34, 47, 62, 0.15);
  padding: 4px 0;
}
.tox:not(.tox-tinymce-inline).tox-tinymce--toolbar-sticky-on.tox-tinymce--toolbar-bottom .tox-editor-header {
  box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.2), 0 8px 8px -4px rgba(34, 47, 62, 0.15);
}
.tox.tox:not(.tox-tinymce-inline) .tox-editor-header.tox-editor-header--empty {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}
.tox-editor-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}
.tox-editor-dock-fadein {
  opacity: 1;
  visibility: visible;
}
.tox-editor-dock-transition {
  transition: visibility 0s linear 0.25s, opacity 0.25s ease;
}
.tox-editor-dock-transition.tox-editor-dock-fadein {
  transition-delay: 0s;
}
.tox .tox-control-wrap {
  flex: 1;
  position: relative;
}
.tox .tox-control-wrap:not(.tox-control-wrap--status-invalid) .tox-control-wrap__status-icon-invalid,
.tox .tox-control-wrap:not(.tox-control-wrap--status-unknown) .tox-control-wrap__status-icon-unknown,
.tox .tox-control-wrap:not(.tox-control-wrap--status-valid) .tox-control-wrap__status-icon-valid {
  display: none;
}
.tox .tox-control-wrap svg {
  display: block;
}
.tox .tox-control-wrap__status-icon-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-control-wrap__status-icon-invalid svg {
  fill: #c00;
}
.tox .tox-control-wrap__status-icon-unknown svg {
  fill: orange;
}
.tox .tox-control-wrap__status-icon-valid svg {
  fill: green;
}
.tox:not([dir=rtl]) .tox-control-wrap--status-invalid .tox-textfield,
.tox:not([dir=rtl]) .tox-control-wrap--status-unknown .tox-textfield,
.tox:not([dir=rtl]) .tox-control-wrap--status-valid .tox-textfield {
  padding-right: 32px;
}
.tox:not([dir=rtl]) .tox-control-wrap__status-icon-wrap {
  right: 4px;
}
.tox[dir=rtl] .tox-control-wrap--status-invalid .tox-textfield,
.tox[dir=rtl] .tox-control-wrap--status-unknown .tox-textfield,
.tox[dir=rtl] .tox-control-wrap--status-valid .tox-textfield {
  padding-left: 32px;
}
.tox[dir=rtl] .tox-control-wrap__status-icon-wrap {
  left: 4px;
}
.tox .tox-custom-preview {
  border-color: #eeeeee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  flex: 1;
  padding: 8px;
}
.tox .tox-autocompleter {
  max-width: 25em;
}
.tox .tox-autocompleter .tox-menu {
  box-sizing: border-box;
  max-width: 25em;
}
.tox .tox-autocompleter .tox-autocompleter-highlight {
  font-weight: bold;
}
.tox .tox-color-input {
  display: flex;
  position: relative;
  z-index: 1;
}
.tox .tox-color-input .tox-textfield {
  z-index: -1;
}
.tox .tox-color-input span {
  border-color: rgba(34 47 3.1%);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  height: 24px;
  position: absolute;
  top: 6px;
  width: 24px;
}
@media (forced-colors: active) {
  .tox .tox-color-input span {
    border-color: currentColor;
    border-width: 2px !important;
    forced-color-adjust: none;
  }
}
.tox .tox-color-input span:hover:not([aria-disabled=true]),
.tox .tox-color-input span:focus:not([aria-disabled=true]) {
  border-color: #006ce7;
  cursor: pointer;
}
.tox .tox-color-input span::before {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%), linear-gradient(-45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%), linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.25) 75%);
  background-position: 0 0, 0 6px, 6px -6px, -6px 0;
  background-size: 12px 12px;
  border: 1px solid #fff;
  border-radius: 6px;
  box-sizing: border-box;
  content: '';
  height: 24px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 24px;
  z-index: -1;
}
@media (forced-colors: active) {
  .tox .tox-color-input span::before {
    border: none;
  }
}
.tox .tox-color-input span[aria-disabled=true] {
  cursor: not-allowed;
}
.tox:not([dir=rtl]) .tox-color-input {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox:not([dir=rtl]) .tox-color-input .tox-textfield {
  padding-left: 36px;
}
.tox:not([dir=rtl]) .tox-color-input span {
  left: 6px;
}
.tox[dir="rtl"] .tox-color-input {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox[dir="rtl"] .tox-color-input .tox-textfield {
  padding-right: 36px;
}
.tox[dir="rtl"] .tox-color-input span {
  right: 6px;
}
.tox .tox-label,
.tox .tox-toolbar-label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
  padding: 0 8px 0 0;
  text-transform: none;
  white-space: nowrap;
}
.tox .tox-toolbar-label {
  padding: 0 8px;
}
.tox[dir=rtl] .tox-label {
  padding: 0 0 0 8px;
}
.tox .tox-form {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.tox .tox-form__group {
  box-sizing: border-box;
  margin-bottom: 4px;
}
.tox .tox-form-group--maximize {
  flex: 1;
}
.tox .tox-form__group--error {
  color: #c00;
}
.tox .tox-form__group--collection {
  display: flex;
}
.tox .tox-form__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tox .tox-form__grid--2col > .tox-form__group {
  width: calc(50% - (8px / 2));
}
.tox .tox-form__grid--3col > .tox-form__group {
  width: calc(100% / 3 - (8px / 2));
}
.tox .tox-form__grid--4col > .tox-form__group {
  width: calc(25% - (8px / 2));
}
.tox .tox-form__controls-h-stack {
  align-items: center;
  display: flex;
}
.tox .tox-form__group--inline {
  align-items: center;
  display: flex;
}
.tox .tox-form__group--stretched {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.tox .tox-form__group--stretched .tox-textarea {
  flex: 1;
}
.tox .tox-form__group--stretched .tox-navobj {
  display: flex;
  flex: 1;
}
.tox .tox-form__group--stretched .tox-navobj :nth-child(2) {
  flex: 1;
  height: 100%;
}
.tox:not([dir=rtl]) .tox-form__controls-h-stack > *:not(:first-child) {
  margin-left: 4px;
}
.tox[dir=rtl] .tox-form__controls-h-stack > *:not(:first-child) {
  margin-right: 4px;
}
.tox .tox-lock.tox-locked .tox-lock-icon__unlock,
.tox .tox-lock:not(.tox-locked) .tox-lock-icon__lock {
  display: none;
}
.tox .tox-textfield,
.tox .tox-toolbar-textfield,
.tox .tox-listboxfield .tox-listbox--select,
.tox .tox-textarea,
.tox .tox-textarea-wrap .tox-textarea:focus {
  appearance: none;
  background-color: #fff;
  border-color: #eeeeee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  min-height: 34px;
  outline: none;
  padding: 5px 5.5px;
  resize: none;
  width: 100%;
}
.tox .tox-textfield[disabled],
.tox .tox-textarea[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-textfield:focus,
.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea-wrap:focus-within,
.tox .tox-textarea:focus,
.tox .tox-custom-editor:focus-within {
  background-color: #fff;
  border-color: #006ce7;
  box-shadow: 0 0 0 1px #006ce7;
  outline: none;
}
.tox .tox-toolbar-textfield {
  border-width: 0;
  margin-bottom: 3px;
  margin-top: 2px;
  max-width: 250px;
}
.tox .tox-naked-btn {
  background-color: transparent;
  border: 0;
  border-color: transparent;
  box-shadow: unset;
  color: #006ce7;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
}
.tox .tox-naked-btn svg {
  display: block;
  fill: #222f3e;
}
.tox:not([dir=rtl]) .tox-toolbar-textfield + * {
  margin-left: 4px;
}
.tox[dir=rtl] .tox-toolbar-textfield + * {
  margin-right: 4px;
}
.tox .tox-listboxfield {
  cursor: pointer;
  position: relative;
}
.tox .tox-listboxfield .tox-listbox--select[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-listbox__select-label {
  cursor: default;
  flex: 1;
  margin: 0 4px;
}
.tox .tox-listbox__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}
.tox .tox-listbox__select-chevron svg {
  fill: #222f3e;
}
@media (forced-colors: active) {
  .tox .tox-listbox__select-chevron svg {
    fill: currentColor !important;
  }
}
.tox .tox-listboxfield .tox-listbox--select {
  align-items: center;
  display: flex;
}
.tox:not([dir=rtl]) .tox-listboxfield svg {
  right: 8px;
}
.tox[dir=rtl] .tox-listboxfield svg {
  left: 8px;
}
.tox .tox-selectfield {
  cursor: pointer;
  position: relative;
}
.tox .tox-selectfield select {
  appearance: none;
  background-color: #fff;
  border-color: #eeeeee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: #222f3e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  min-height: 34px;
  outline: none;
  padding: 5px 5.5px;
  resize: none;
  width: 100%;
}
.tox .tox-selectfield select[disabled] {
  background-color: #f2f2f2;
  color: rgba(34, 47, 62, 0.85);
  cursor: not-allowed;
}
.tox .tox-selectfield select::-ms-expand {
  display: none;
}
.tox .tox-selectfield select:focus {
  background-color: #fff;
  border-color: #006ce7;
  box-shadow: 0 0 0 1px #006ce7;
  outline: none;
}
.tox .tox-selectfield svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox:not([dir=rtl]) .tox-selectfield select[size="0"],
.tox:not([dir=rtl]) .tox-selectfield select[size="1"] {
  padding-right: 24px;
}
.tox:not([dir=rtl]) .tox-selectfield svg {
  right: 8px;
}
.tox[dir=rtl] .tox-selectfield select[size="0"],
.tox[dir=rtl] .tox-selectfield select[size="1"] {
  padding-left: 24px;
}
.tox[dir=rtl] .tox-selectfield svg {
  left: 8px;
}
.tox .tox-textarea-wrap {
  border-color: #eeeeee;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  overflow: hidden;
}
.tox .tox-textarea {
  appearance: textarea;
  white-space: pre-wrap;
}
.tox .tox-textarea-wrap .tox-textarea {
  border: none;
}
.tox .tox-textarea-wrap .tox-textarea:focus {
  border: none;
}
.tox-fullscreen {
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  overscroll-behavior: none;
  padding: 0;
  touch-action: pinch-zoom;
  width: 100%;
}
.tox.tox-tinymce.tox-fullscreen .tox-statusbar__resize-handle {
  display: none;
}
.tox.tox-tinymce.tox-fullscreen,
.tox-shadowhost.tox-fullscreen {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1200;
}
.tox.tox-tinymce.tox-fullscreen {
  background-color: transparent;
}
.tox-fullscreen .tox.tox-tinymce-aux,
.tox-fullscreen ~ .tox.tox-tinymce-aux {
  z-index: 1201;
}
.tox .tox-help__more-link {
  list-style: none;
  margin-top: 1em;
}
.tox .tox-imagepreview {
  background-color: #666;
  height: 380px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.tox .tox-imagepreview.tox-imagepreview__loaded {
  overflow: auto;
}
.tox .tox-imagepreview__container {
  display: flex;
  left: 100vw;
  position: absolute;
  top: 100vw;
}
.tox .tox-imagepreview__image {
  background: url(data:image/gif;base64,R0lGODdhDAAMAIABAMzMzP///ywAAAAADAAMAAACFoQfqYeabNyDMkBQb81Uat85nxguUAEAOw==);
}
.tox .tox-image-tools .tox-spacer {
  flex: 1;
}
.tox .tox-image-tools .tox-bar {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
}
.tox .tox-image-tools .tox-imagepreview,
.tox .tox-image-tools .tox-imagepreview + .tox-bar {
  margin-top: 8px;
}
.tox .tox-image-tools .tox-croprect-block {
  background: black;
  filter: alpha(opacity=50);
  opacity: 0.5;
  position: absolute;
  zoom: 1;
}
.tox .tox-image-tools .tox-croprect-handle {
  border: 2px solid white;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.tox .tox-image-tools .tox-croprect-handle-move {
  border: 0;
  cursor: move;
  position: absolute;
}
.tox .tox-image-tools .tox-croprect-handle-nw {
  border-width: 2px 0 0 2px;
  cursor: nw-resize;
  left: 100px;
  margin: -2px 0 0 -2px;
  top: 100px;
}
.tox .tox-image-tools .tox-croprect-handle-ne {
  border-width: 2px 2px 0 0;
  cursor: ne-resize;
  left: 200px;
  margin: -2px 0 0 -20px;
  top: 100px;
}
.tox .tox-image-tools .tox-croprect-handle-sw {
  border-width: 0 0 2px 2px;
  cursor: sw-resize;
  left: 100px;
  margin: -20px 2px 0 -2px;
  top: 200px;
}
.tox .tox-image-tools .tox-croprect-handle-se {
  border-width: 0 2px 2px 0;
  cursor: se-resize;
  left: 200px;
  margin: -20px 0 0 -20px;
  top: 200px;
}
.tox .tox-insert-table-picker {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 170px;
}
.tox .tox-insert-table-picker > div {
  border-color: #eeeeee;
  border-style: solid;
  border-width: 0 1px 1px 0;
  box-sizing: border-box;
  height: 17px;
  width: 17px;
}
.tox .tox-collection--list .tox-collection__group .tox-insert-table-picker {
  margin: -4px -4px;
}
.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: #006ce7;
  border-color: #eeeeee;
}
@media (forced-colors: active) {
  .tox .tox-insert-table-picker .tox-insert-table-picker__selected {
    border-color: Highlight;
    filter: contrast(50%);
  }
}
.tox .tox-insert-table-picker__label {
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  padding: 4px;
  text-align: center;
  width: 100%;
}
.tox:not([dir=rtl]) {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox:not([dir=rtl]) .tox-insert-table-picker > div:nth-child(10n) {
  border-right: 0;
}
.tox[dir=rtl] {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox[dir=rtl] .tox-insert-table-picker > div:nth-child(10n+1) {
  border-right: 0;
}
.tox {
  /* stylelint-disable */
  /* stylelint-enable */
}
.tox .tox-menu {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  z-index: 1150;
}
.tox .tox-menu.tox-collection.tox-collection--list {
  padding: 0 4px;
}
.tox .tox-menu.tox-collection.tox-collection--toolbar {
  padding: 8px;
}
.tox .tox-menu.tox-collection.tox-collection--grid {
  padding: 8px;
}
@media only screen and (min-width: 768px ) {
  .tox .tox-menu .tox-collection__item-label {
    overflow-wrap: break-word;
    word-break: normal;
  }
  .tox .tox-dialog__popups .tox-menu .tox-collection__item-label {
    word-break: break-all;
  }
}
.tox .tox-menu__label h1,
.tox .tox-menu__label h2,
.tox .tox-menu__label h3,
.tox .tox-menu__label h4,
.tox .tox-menu__label h5,
.tox .tox-menu__label h6,
.tox .tox-menu__label p,
.tox .tox-menu__label blockquote,
.tox .tox-menu__label code {
  margin: 0;
}
.tox .tox-menubar {
  background: repeating-linear-gradient(transparent 0 1px, transparent 1px 39px) center top 39px / 100% calc(100% - 39px) no-repeat;
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: wrap;
  grid-column: 1 / -1;
  grid-row: 1;
  padding: 0 11px 0 12px;
}
.tox .tox-promotion + .tox-menubar {
  grid-column: 1;
}
.tox .tox-promotion {
  background: repeating-linear-gradient(transparent 0 1px, transparent 1px 39px) center top 39px / 100% calc(100% - 39px) no-repeat;
  background-color: #fff;
  grid-column: 2;
  grid-row: 1;
  padding-inline-end: 8px;
  padding-inline-start: 4px;
  padding-top: 5px;
}
.tox .tox-promotion-link {
  align-items: unsafe center;
  background-color: #E8F1F8;
  border-radius: 5px;
  color: #086BE6;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 26.6px;
  padding: 4px 8px;
  white-space: nowrap;
}
.tox .tox-promotion-link:hover {
  background-color: #B4D7FF;
}
.tox .tox-promotion-link:focus {
  background-color: #D9EDF7;
}
/* Deprecated. Remove in next major release */
.tox .tox-mbtn {
  align-items: center;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 28px;
  justify-content: center;
  margin: 5px 1px 6px 0;
  outline: none;
  padding: 0 4px;
  text-transform: none;
  width: auto;
}
.tox .tox-mbtn[disabled] {
  background-color: #fff;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-mbtn:focus:not(:disabled) {
  background: #fff;
  border: 0;
  box-shadow: none;
  color: #222f3e;
  position: relative;
  z-index: 1;
}
.tox .tox-mbtn:focus:not(:disabled)::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-mbtn:focus:not(:disabled)::after {
    border: 2px solid highlight;
  }
}
.tox .tox-mbtn--active,
.tox .tox-mbtn:not(:disabled).tox-mbtn--active:focus {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background: #f0f0f0;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-mbtn__select-label {
  cursor: default;
  font-weight: normal;
  margin: 0 4px;
}
.tox .tox-mbtn[disabled] .tox-mbtn__select-label {
  cursor: not-allowed;
}
.tox .tox-mbtn__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
  display: none;
}
.tox .tox-notification {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  display: grid;
  font-size: 14px;
  font-weight: normal;
  grid-template-columns: minmax(40px, 1fr) auto minmax(40px, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  opacity: 0;
  padding: 4px;
  transition: transform 100ms ease-in, opacity 150ms ease-in;
  width: max-content;
}
.tox .tox-notification a {
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification p {
  font-size: 14px;
  font-weight: normal;
}
.tox .tox-notification:focus {
  border-color: #006ce7;
  box-shadow: 0 0 0 1px #006ce7;
}
.tox .tox-notification--in {
  opacity: 1;
}
.tox .tox-notification--success {
  background-color: #e4eeda;
  border-color: #d7e6c8;
  color: #222f3e;
}
.tox .tox-notification--success p {
  color: #222f3e;
}
.tox .tox-notification--success a {
  color: #517342;
}
.tox .tox-notification--success a:hover,
.tox .tox-notification--success a:focus {
  color: #24321d;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--success a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #517342;
  outline-offset: 2px;
}
.tox .tox-notification--success a:active {
  color: #0d120a;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--success svg {
  fill: #222f3e;
}
.tox .tox-notification--error {
  background-color: #f5cccc;
  border-color: #f0b3b3;
  color: #222f3e;
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-notification--error p {
  color: #222f3e;
}
.tox .tox-notification--error a {
  color: #77181f;
}
.tox .tox-notification--error a:hover,
.tox .tox-notification--error a:focus {
  color: #220709;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--error a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #77181f;
  outline-offset: 2px;
}
.tox .tox-notification--error a:active {
  color: #000000;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--error svg {
  fill: #222f3e;
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  background-color: #fff5cc;
  border-color: #fff0b3;
  color: #222f3e;
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-notification--warn p,
.tox .tox-notification--warning p {
  color: #222f3e;
}
.tox .tox-notification--warn a,
.tox .tox-notification--warning a {
  color: #7a6e25;
}
.tox .tox-notification--warn a:hover,
.tox .tox-notification--warning a:hover,
.tox .tox-notification--warn a:focus,
.tox .tox-notification--warning a:focus {
  color: #2c280d;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--warn a:focus-visible,
.tox .tox-notification--warning a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #7a6e25;
  outline-offset: 2px;
}
.tox .tox-notification--warn a:active,
.tox .tox-notification--warning a:active {
  color: #050502;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--warn svg,
.tox .tox-notification--warning svg {
  fill: #222f3e;
}
.tox .tox-notification--info {
  background-color: #d6e7fb;
  border-color: #c1dbf9;
  color: #222f3e;
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox .tox-notification--info p {
  color: #222f3e;
}
.tox .tox-notification--info a {
  color: #2a64a6;
}
.tox .tox-notification--info a:hover,
.tox .tox-notification--info a:focus {
  color: #163355;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--info a:focus-visible {
  border-radius: 1px;
  outline: 2px solid #2a64a6;
  outline-offset: 2px;
}
.tox .tox-notification--info a:active {
  color: #0b1a2c;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.tox .tox-notification--info svg {
  fill: #222f3e;
}
.tox .tox-notification__body {
  align-self: center;
  color: #222f3e;
  font-size: 14px;
  grid-column-end: 3;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-row-start: 1;
  text-align: center;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;
}
.tox .tox-notification__body > * {
  margin: 0;
}
.tox .tox-notification__body > * + * {
  margin-top: 1rem;
}
.tox .tox-notification__icon {
  align-self: center;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
  justify-self: end;
}
.tox .tox-notification__icon svg {
  display: block;
}
.tox .tox-notification__dismiss {
  align-self: start;
  grid-column-end: 4;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-row-start: 1;
  justify-self: end;
}
.tox .tox-notification .tox-progress-bar {
  grid-column-end: 4;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 2;
  justify-self: center;
}
.tox .tox-notification-container-dock-fadeout {
  opacity: 0;
  visibility: hidden;
}
.tox .tox-notification-container-dock-fadein {
  opacity: 1;
  visibility: visible;
}
.tox .tox-notification-container-dock-transition {
  transition: visibility 0s linear 0.3s, opacity 0.3s ease;
}
.tox .tox-notification-container-dock-transition.tox-notification-container-dock-fadein {
  transition-delay: 0s;
}
.tox .tox-pop {
  display: inline-block;
  position: relative;
}
.tox .tox-pop--resizing {
  transition: width 0.1s ease;
}
.tox .tox-pop--resizing .tox-toolbar,
.tox .tox-pop--resizing .tox-toolbar__group {
  flex-wrap: nowrap;
}
.tox .tox-pop--transition {
  transition: 0.15s ease;
  transition-property: left, right, top, bottom;
}
.tox .tox-pop--transition::before,
.tox .tox-pop--transition::after {
  transition: all 0.15s, visibility 0s, opacity 0.075s ease 0.075s;
}
.tox .tox-pop__dialog {
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  min-width: 0;
  overflow: hidden;
}
.tox .tox-pop__dialog > *:not(.tox-toolbar) {
  margin: 4px 4px 4px 8px;
}
.tox .tox-pop__dialog .tox-toolbar {
  background-color: transparent;
  margin-bottom: -1px;
}
.tox .tox-pop::before,
.tox .tox-pop::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  opacity: 1;
  position: absolute;
  width: 0;
}
@media (forced-colors: active) {
  .tox .tox-pop::before,
  .tox .tox-pop::after {
    content: none;
  }
}
.tox .tox-pop.tox-pop--inset::before,
.tox .tox-pop.tox-pop--inset::after {
  opacity: 0;
  transition: all 0s 0.15s, visibility 0s, opacity 0.075s ease;
}
.tox .tox-pop.tox-pop--bottom::before,
.tox .tox-pop.tox-pop--bottom::after {
  left: 50%;
  top: 100%;
}
.tox .tox-pop.tox-pop--bottom::after {
  border-color: #fff transparent transparent transparent;
  border-width: 8px;
  margin-left: -8px;
  margin-top: -1px;
}
.tox .tox-pop.tox-pop--bottom::before {
  border-color: #eeeeee transparent transparent transparent;
  border-width: 9px;
  margin-left: -9px;
}
.tox .tox-pop.tox-pop--top::before,
.tox .tox-pop.tox-pop--top::after {
  left: 50%;
  top: 0;
  transform: translateY(-100%);
}
.tox .tox-pop.tox-pop--top::after {
  border-color: transparent transparent #fff transparent;
  border-width: 8px;
  margin-left: -8px;
  margin-top: 1px;
}
.tox .tox-pop.tox-pop--top::before {
  border-color: transparent transparent #eeeeee transparent;
  border-width: 9px;
  margin-left: -9px;
}
.tox .tox-pop.tox-pop--left::before,
.tox .tox-pop.tox-pop--left::after {
  left: 0;
  top: calc(50% - 1px);
  transform: translateY(-50%);
}
.tox .tox-pop.tox-pop--left::after {
  border-color: transparent #fff transparent transparent;
  border-width: 8px;
  margin-left: -15px;
}
.tox .tox-pop.tox-pop--left::before {
  border-color: transparent #eeeeee transparent transparent;
  border-width: 10px;
  margin-left: -19px;
}
.tox .tox-pop.tox-pop--right::before,
.tox .tox-pop.tox-pop--right::after {
  left: 100%;
  top: calc(50% + 1px);
  transform: translateY(-50%);
}
.tox .tox-pop.tox-pop--right::after {
  border-color: transparent transparent transparent #fff;
  border-width: 8px;
  margin-left: -1px;
}
.tox .tox-pop.tox-pop--right::before {
  border-color: transparent transparent transparent #eeeeee;
  border-width: 10px;
  margin-left: -1px;
}
.tox .tox-pop.tox-pop--align-left::before,
.tox .tox-pop.tox-pop--align-left::after {
  left: 20px;
}
.tox .tox-pop.tox-pop--align-right::before,
.tox .tox-pop.tox-pop--align-right::after {
  left: calc(100% - 20px);
}
.tox .tox-sidebar-wrap {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
}
.tox .tox-sidebar {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.tox .tox-sidebar__slider {
  display: flex;
  overflow: hidden;
}
.tox .tox-sidebar__pane-container {
  display: flex;
}
.tox .tox-sidebar__pane {
  display: flex;
}
.tox .tox-sidebar--sliding-closed {
  opacity: 0;
}
.tox .tox-sidebar--sliding-open {
  opacity: 1;
}
.tox .tox-sidebar--sliding-growing,
.tox .tox-sidebar--sliding-shrinking {
  transition: width 0.5s ease, opacity 0.5s ease;
}
.tox .tox-selector {
  background-color: #4099ff;
  border-color: #4099ff;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  height: 10px;
  position: absolute;
  width: 10px;
}
.tox.tox-platform-touch .tox-selector {
  height: 12px;
  width: 12px;
}
.tox .tox-slider {
  align-items: center;
  display: flex;
  flex: 1;
  height: 24px;
  justify-content: center;
  position: relative;
}
.tox .tox-slider__rail {
  background-color: transparent;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  height: 10px;
  min-width: 120px;
  width: 100%;
}
.tox .tox-slider__handle {
  background-color: #006ce7;
  border: 2px solid #0054b4;
  border-radius: 6px;
  box-shadow: none;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 14px;
}
.tox .tox-form__controls-h-stack > .tox-slider:not(:first-of-type) {
  margin-inline-start: 8px;
}
.tox .tox-form__controls-h-stack > .tox-form__group + .tox-slider {
  margin-inline-start: 32px;
}
.tox .tox-form__controls-h-stack > .tox-slider + .tox-form__group {
  margin-inline-start: 32px;
}
.tox .tox-source-code {
  overflow: auto;
}
.tox .tox-spinner {
  display: flex;
}
.tox .tox-spinner > div {
  animation: tam-bouncing-dots 1.5s ease-in-out 0s infinite both;
  background-color: rgba(34, 47, 62, 0.7);
  border-radius: 100%;
  height: 8px;
  width: 8px;
}
.tox .tox-spinner > div:nth-child(1) {
  animation-delay: -0.32s;
}
.tox .tox-spinner > div:nth-child(2) {
  animation-delay: -0.16s;
}
@keyframes tam-bouncing-dots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.tox:not([dir=rtl]) .tox-spinner > div:not(:first-child) {
  margin-left: 4px;
}
.tox[dir=rtl] .tox-spinner > div:not(:first-child) {
  margin-right: 4px;
}
.tox .tox-statusbar {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  color: rgba(34, 47, 62, 0.7);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: normal;
  height: 25px;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: none;
}
.tox .tox-statusbar__path {
  display: flex;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tox .tox-statusbar__right-container {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}
.tox .tox-statusbar__help-text {
  text-align: center;
}
.tox .tox-statusbar__text-container {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  height: 16px;
  justify-content: space-between;
}
@media only screen and (min-width: 768px ) {
  .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__help-text,
  .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__right-container,
  .tox .tox-statusbar__text-container.tox-statusbar__text-container-3-cols > .tox-statusbar__path {
    flex: 0 0 calc(100% / 3);
  }
}
.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-end {
  justify-content: flex-end;
}
.tox .tox-statusbar__text-container.tox-statusbar__text-container--flex-start {
  justify-content: flex-start;
}
.tox .tox-statusbar__text-container.tox-statusbar__text-container--space-around {
  justify-content: space-around;
}
.tox .tox-statusbar__path > * {
  display: inline;
  white-space: nowrap;
}
.tox .tox-statusbar__wordcount {
  flex: 0 0 auto;
  margin-left: 1ch;
}
@media only screen and (max-width: 767px ) {
  .tox .tox-statusbar__text-container .tox-statusbar__help-text {
    display: none;
  }
  .tox .tox-statusbar__text-container .tox-statusbar__help-text:only-child {
    display: block;
  }
}
.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
  color: rgba(34, 47, 62, 0.7);
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.tox .tox-statusbar a:hover:not(:disabled):not([aria-disabled=true]),
.tox .tox-statusbar__path-item:hover:not(:disabled):not([aria-disabled=true]),
.tox .tox-statusbar__wordcount:hover:not(:disabled):not([aria-disabled=true]),
.tox .tox-statusbar a:focus:not(:disabled):not([aria-disabled=true]),
.tox .tox-statusbar__path-item:focus:not(:disabled):not([aria-disabled=true]),
.tox .tox-statusbar__wordcount:focus:not(:disabled):not([aria-disabled=true]) {
  color: #222f3e;
  cursor: pointer;
}
.tox .tox-statusbar a:focus-visible::after,
.tox .tox-statusbar__path-item:focus-visible::after,
.tox .tox-statusbar__wordcount:focus-visible::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-statusbar a:focus-visible::after,
  .tox .tox-statusbar__path-item:focus-visible::after,
  .tox .tox-statusbar__wordcount:focus-visible::after {
    border: 2px solid highlight;
  }
}
.tox .tox-statusbar__branding svg {
  fill: rgba(34, 47, 62, 0.8);
  height: 1em;
  margin-left: 0.3em;
  width: auto;
}
@media (forced-colors: active) {
  .tox .tox-statusbar__branding svg {
    fill: currentColor;
  }
}
.tox .tox-statusbar__branding a {
  /* stylelint-disable-line no-descending-specificity */
  align-items: center;
  display: inline-flex;
}
.tox .tox-statusbar__branding a:hover:not(:disabled):not([aria-disabled=true]) svg,
.tox .tox-statusbar__branding a:focus:not(:disabled):not([aria-disabled=true]) svg {
  fill: #222f3e;
}
.tox .tox-statusbar__resize-handle {
  align-items: flex-end;
  align-self: stretch;
  cursor: nwse-resize;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-bottom: 3px;
  margin-left: 4px;
  margin-right: calc(3px - 8px);
  margin-top: 3px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.tox .tox-statusbar__resize-handle svg {
  display: block;
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-statusbar__resize-handle:hover svg,
.tox .tox-statusbar__resize-handle:focus svg {
  fill: #222f3e;
}
.tox .tox-statusbar__resize-handle:focus-visible {
  background-color: transparent;
  border-radius: 1px 1px 5px 1px;
  box-shadow: 0 0 0 2px transparent;
}
.tox .tox-statusbar__resize-handle:focus-visible::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-statusbar__resize-handle:focus-visible::after {
    border: 2px solid highlight;
  }
}
.tox .tox-statusbar__resize-handle:only-child {
  margin-left: auto;
}
.tox:not([dir=rtl]) .tox-statusbar__path > * {
  margin-right: 4px;
}
.tox:not([dir=rtl]) .tox-statusbar__branding {
  margin-left: 2ch;
}
.tox[dir=rtl] .tox-statusbar {
  flex-direction: row-reverse;
}
.tox[dir=rtl] .tox-statusbar__path > * {
  margin-left: 4px;
}
.tox[dir=rtl] .tox-statusbar__branding svg {
  margin-left: 0;
  margin-right: 0.3em;
}
.tox .tox-throbber {
  z-index: 1299;
}
.tox .tox-throbber__busy-spinner {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.tox .tox-tbtn {
  align-items: center;
  background: #fff;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 28px;
  justify-content: center;
  margin: 6px 1px 5px 0;
  outline: none;
  padding: 0;
  text-transform: none;
  width: 34px;
}
@media (forced-colors: active) {
  .tox .tox-tbtn:hover,
  .tox .tox-tbtn.tox-tbtn:hover {
    outline: 1px dashed currentColor;
  }
  .tox .tox-tbtn.tox-tbtn--active,
  .tox .tox-tbtn.tox-tbtn--enabled,
  .tox .tox-tbtn.tox-tbtn--enabled:hover,
  .tox .tox-tbtn.tox-tbtn--enabled:focus,
  .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
    outline: 1px solid currentColor;
    position: relative;
  }
}
.tox .tox-tbtn svg {
  display: block;
  fill: #222f3e;
}
@media (forced-colors: active) {
  .tox .tox-tbtn svg {
    fill: currentColor !important;
  }
  .tox .tox-tbtn svg.tox-tbtn--enabled,
  .tox .tox-tbtn svg:focus:not(.tox-tbtn--disabled) {
    fill: currentColor !important;
  }
  .tox .tox-tbtn svg.tox-tbtn--disabled,
  .tox .tox-tbtn svg.tox-tbtn--disabled:hover,
  .tox .tox-tbtn svg .tox-tbtn:disabled,
  .tox .tox-tbtn svg .tox-tbtn:disabled:hover {
    filter: contrast(0%);
  }
}
.tox .tox-tbtn.tox-tbtn-more {
  padding-left: 5px;
  padding-right: 5px;
  width: inherit;
}
.tox .tox-tbtn:focus {
  background: #fff;
  border: 0;
  box-shadow: none;
  position: relative;
  z-index: 1;
}
.tox .tox-tbtn:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-tbtn:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-tbtn:hover {
  background: #f0f0f0;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-tbtn:hover svg {
  fill: #222f3e;
}
.tox .tox-tbtn:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-tbtn:active svg {
  fill: #222f3e;
}
.tox .tox-tbtn--disabled .tox-tbtn--enabled svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tbtn--disabled,
.tox .tox-tbtn--disabled:hover,
.tox .tox-tbtn:disabled,
.tox .tox-tbtn:disabled:hover {
  background: #fff;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  /* stylelint-disable-line no-descending-specificity */
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tbtn--active,
.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover,
.tox .tox-tbtn--enabled:focus {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
  position: relative;
}
.tox .tox-tbtn--active > *,
.tox .tox-tbtn--enabled > *,
.tox .tox-tbtn--enabled:hover > *,
.tox .tox-tbtn--enabled:focus > * {
  transform: none;
}
.tox .tox-tbtn--active svg,
.tox .tox-tbtn--enabled svg,
.tox .tox-tbtn--enabled:hover svg,
.tox .tox-tbtn--enabled:focus svg {
  /* stylelint-disable-line no-descending-specificity */
  fill: #222f3e;
}
.tox .tox-tbtn--active.tox-tbtn--disabled svg,
.tox .tox-tbtn--enabled.tox-tbtn--disabled svg,
.tox .tox-tbtn--enabled:hover.tox-tbtn--disabled svg,
.tox .tox-tbtn--enabled:focus.tox-tbtn--disabled svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tbtn--enabled:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-tbtn--enabled:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
  color: #222f3e;
}
.tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
  fill: #222f3e;
}
.tox .tox-tbtn:active > * {
  transform: none;
}
.tox .tox-tbtn--md {
  height: 42px;
  width: 51px;
}
.tox .tox-tbtn--lg {
  flex-direction: column;
  height: 56px;
  width: 68px;
}
.tox .tox-tbtn--return {
  align-self: stretch;
  height: unset;
  width: 16px;
}
.tox .tox-tbtn--labeled {
  padding: 0 4px;
  width: unset;
}
.tox .tox-tbtn__vlabel {
  display: block;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.025em;
  margin-bottom: 4px;
  white-space: nowrap;
}
.tox .tox-number-input {
  background: #f7f7f7;
  border-radius: 3px;
  display: flex;
  margin: 6px 1px 5px 0;
  position: relative;
  width: auto;
}
.tox .tox-number-input:focus {
  background: #f7f7f7;
}
.tox .tox-number-input:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-number-input:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-number-input .tox-input-wrapper {
  display: flex;
  pointer-events: none;
  position: relative;
  text-align: center;
}
.tox .tox-number-input .tox-input-wrapper:focus {
  background-color: #f7f7f7;
  z-index: 1;
}
.tox .tox-number-input .tox-input-wrapper:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-number-input .tox-input-wrapper:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-number-input .tox-input-wrapper:has(input:focus)::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-number-input .tox-input-wrapper:has(input:focus)::after {
    border: 2px solid highlight;
  }
}
.tox .tox-number-input input {
  border-radius: 3px;
  color: #222f3e;
  font-size: 14px;
  margin: 2px 0;
  pointer-events: all;
  position: relative;
  width: 60px;
}
.tox .tox-number-input input:hover {
  background: #f0f0f0;
  color: #222f3e;
}
.tox .tox-number-input input:focus {
  background-color: #f7f7f7;
}
.tox .tox-number-input input:disabled {
  background: #fff;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-number-input button {
  color: #222f3e;
  height: 28px;
  position: relative;
  text-align: center;
  width: 24px;
}
@media (forced-colors: active) {
  .tox .tox-number-input button:hover,
  .tox .tox-number-input button:focus,
  .tox .tox-number-input button:active {
    outline: 1px solid currentColor !important;
  }
}
.tox .tox-number-input button svg {
  display: block;
  fill: #222f3e;
  margin: 0 auto;
  transform: scale(0.67);
}
@media (forced-colors: active) {
  .tox .tox-number-input button svg,
  .tox .tox-number-input button svg:active,
  .tox .tox-number-input button svg:hover {
    fill: currentColor !important;
  }
  .tox .tox-number-input button svg:disabled {
    filter: contrast(0);
  }
}
.tox .tox-number-input button:focus {
  background: #f7f7f7;
  z-index: 1;
}
.tox .tox-number-input button:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-number-input button:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-number-input button:hover {
  background: #f0f0f0;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-number-input button:hover svg {
  fill: #222f3e;
}
.tox .tox-number-input button:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-number-input button:active svg {
  fill: #222f3e;
}
.tox .tox-number-input button:disabled {
  background: #fff;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-number-input button:disabled svg {
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-number-input button.minus {
  border-radius: 3px 0 0 3px;
}
.tox .tox-number-input button.plus {
  border-radius: 0 3px 3px 0;
}
.tox .tox-number-input:focus:not(:active) > button,
.tox .tox-number-input:focus:not(:active) > .tox-input-wrapper {
  background: #f7f7f7;
}
.tox .tox-tbtn--select {
  margin: 6px 1px 5px 0;
  padding: 0 4px;
  width: auto;
}
.tox .tox-tbtn__select-label {
  cursor: default;
  font-weight: normal;
  height: initial;
  margin: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tox .tox-tbtn__select-chevron {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 16px;
}
.tox .tox-tbtn__select-chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}
@media (forced-colors: active) {
  .tox .tox-tbtn__select-chevron svg {
    fill: currentColor;
  }
}
.tox .tox-tbtn--bespoke {
  background: #f7f7f7;
}
.tox .tox-tbtn--bespoke:focus {
  background: #f7f7f7;
}
.tox .tox-tbtn--bespoke + .tox-tbtn--bespoke {
  margin-inline-start: 4px;
}
.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 7em;
}
.tox .tox-tbtn--disabled .tox-tbtn__select-label,
.tox .tox-tbtn--select:disabled .tox-tbtn__select-label {
  cursor: not-allowed;
}
.tox .tox-split-button {
  border: 0;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin: 6px 1px 5px 0;
}
.tox .tox-split-button:hover {
  box-shadow: 0 0 0 1px #f0f0f0 inset;
}
.tox .tox-split-button:focus {
  background: #fff;
  box-shadow: none;
  color: #222f3e;
  position: relative;
  z-index: 1;
}
.tox .tox-split-button:focus::after {
  pointer-events: none;
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (forced-colors: active) {
  .tox .tox-split-button:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-split-button > * {
  border-radius: 0;
}
.tox .tox-split-button > *:nth-child(1) {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.tox .tox-split-button > *:nth-child(2) {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.tox .tox-split-button__chevron {
  width: 16px;
}
.tox .tox-split-button__chevron svg {
  fill: rgba(34, 47, 62, 0.5);
}
@media (forced-colors: active) {
  .tox .tox-split-button__chevron svg {
    fill: currentColor;
  }
}
.tox .tox-split-button .tox-tbtn {
  margin: 0;
}
.tox .tox-split-button:focus .tox-tbtn {
  background-color: transparent;
}
.tox .tox-split-button.tox-tbtn--disabled:hover,
.tox .tox-split-button.tox-tbtn--disabled:focus,
.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:hover,
.tox .tox-split-button.tox-tbtn--disabled .tox-tbtn:focus {
  background: #fff;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
}
.tox.tox-platform-touch .tox-split-button .tox-tbtn--select {
  padding: 0 0px;
}
.tox.tox-platform-touch .tox-split-button .tox-tbtn:not(.tox-tbtn--select):first-child {
  width: 30px;
}
.tox.tox-platform-touch .tox-split-button__chevron {
  width: 20px;
}
.tox .tox-split-button.tox-tbtn--disabled svg .tox-icon-text-color__color,
.tox .tox-split-button.tox-tbtn--disabled svg .tox-icon-highlight-bg-color__color {
  opacity: 0.3;
}
.tox .tox-toolbar-overlord {
  background-color: #fff;
}
.tox .tox-toolbar,
.tox .tox-toolbar__primary,
.tox .tox-toolbar__overflow {
  background-attachment: local;
  background-color: #fff;
  background-image: repeating-linear-gradient(#e3e3e3 0 1px, transparent 1px 39px);
  background-position: center top 40px;
  background-repeat: no-repeat;
  background-size: calc(100% - 11px * 2) calc(100% - 41px);
  display: flex;
  flex: 0 0 auto;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 0 0px;
  transform: perspective(1px);
}
.tox .tox-toolbar-overlord > .tox-toolbar,
.tox .tox-toolbar-overlord > .tox-toolbar__primary,
.tox .tox-toolbar-overlord > .tox-toolbar__overflow {
  background-position: center top 0px;
  background-size: calc(100% - 11px * 2) calc(100% - 0px);
}
.tox .tox-toolbar__overflow.tox-toolbar__overflow--closed {
  height: 0;
  opacity: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
}
.tox .tox-toolbar__overflow--growing {
  transition: height 0.3s ease, opacity 0.2s linear 0.1s;
}
.tox .tox-toolbar__overflow--shrinking {
  transition: opacity 0.3s ease, height 0.2s linear 0.1s, visibility 0s linear 0.3s;
}
.tox .tox-toolbar-overlord,
.tox .tox-anchorbar {
  grid-column: 1 / -1;
}
.tox .tox-menubar + .tox-toolbar,
.tox .tox-menubar + .tox-toolbar-overlord {
  border-top: 1px solid transparent;
  margin-top: -1px;
  padding-bottom: 1px;
  padding-top: 1px;
}
@media (forced-colors: active) {
  .tox .tox-menubar + .tox-toolbar,
  .tox .tox-menubar + .tox-toolbar-overlord {
    outline: 1px solid currentColor;
  }
}
.tox .tox-toolbar--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tox .tox-pop .tox-toolbar {
  border-width: 0;
}
.tox .tox-toolbar--no-divider {
  background-image: none;
}
.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
.tox .tox-toolbar-overlord .tox-toolbar__primary {
  background-position: center top 39px;
}
.tox .tox-editor-header > .tox-toolbar--scrolling,
.tox .tox-toolbar-overlord .tox-toolbar--scrolling:first-child {
  background-image: none;
}
.tox.tox-tinymce-aux .tox-toolbar__overflow {
  background-color: #fff;
  background-position: center top 43px;
  background-size: calc(100% - 8px * 2) calc(100% - 51px);
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2), 0 4px 8px 0 rgba(34, 47, 62, 0.15);
  overscroll-behavior: none;
  padding: 4px 0;
}
@media (forced-colors: active) {
  .tox.tox-tinymce-aux .tox-toolbar__overflow {
    border: solid;
  }
}
.tox-pop .tox-pop__dialog {
  /* stylelint-disable-next-line no-descending-specificity */
}
.tox-pop .tox-pop__dialog .tox-toolbar {
  background-position: center top 43px;
  background-size: calc(100% - 11px * 2) calc(100% - 51px);
  padding: 4px 0;
}
.tox .tox-toolbar__group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
  padding: 0 11px 0 12px;
}
.tox .tox-toolbar__group--pull-right {
  margin-left: auto;
}
.tox .tox-toolbar--scrolling .tox-toolbar__group {
  flex-shrink: 0;
  flex-wrap: nowrap;
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid transparent;
}
.tox[dir=rtl] .tox-toolbar__group:not(:last-of-type) {
  border-left: 1px solid transparent;
}
.tox .tox-tooltip {
  display: inline-block;
  max-width: 15em;
  padding: 8px;
  /*
     * The pointer-events: none is designed to make mouse events bleed through the tooltip
     * to the underlying items. For example, a mouse hovering over a tooltip that hovers over
     * another item should trigger the hover of the item obscured by the tooltip, even though
     * the tooltip is on top
     */
  pointer-events: none;
  position: relative;
  width: max-content;
  z-index: 1150;
}
.tox .tox-tooltip__body {
  background-color: #222f3e;
  border-radius: 6px;
  box-shadow: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  overflow-wrap: break-word;
  padding: 4px 6px;
  text-transform: none;
}
@media (forced-colors: active) {
  .tox .tox-tooltip__body {
    outline: outset 1px;
  }
}
.tox .tox-tooltip__arrow {
  position: absolute;
}
.tox .tox-tooltip--down .tox-tooltip__arrow {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #222f3e;
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.tox .tox-tooltip--up .tox-tooltip__arrow {
  border-bottom: 8px solid #222f3e;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.tox .tox-tooltip--right .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-left: 8px solid #222f3e;
  border-top: 8px solid transparent;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-tooltip--left .tox-tooltip__arrow {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #222f3e;
  border-top: 8px solid transparent;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tox .tox-tree {
  display: flex;
  flex-direction: column;
}
.tox .tox-tree .tox-trbtn {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: #222f3e;
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 28px;
  margin-bottom: 4px;
  margin-top: 4px;
  outline: none;
  overflow: hidden;
  padding: 0;
  padding-left: 8px;
  text-transform: none;
}
.tox .tox-tree .tox-trbtn .tox-tree__label {
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tox .tox-tree .tox-trbtn svg {
  display: block;
  fill: #222f3e;
}
.tox .tox-tree .tox-trbtn:focus {
  background: #f0f0f0;
  border: 0;
  box-shadow: none;
}
.tox .tox-tree .tox-trbtn:hover {
  background: #f0f0f0;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-tree .tox-trbtn:hover svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-trbtn:active {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-tree .tox-trbtn:active svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-trbtn--disabled,
.tox .tox-tree .tox-trbtn--disabled:hover,
.tox .tox-tree .tox-trbtn:disabled,
.tox .tox-tree .tox-trbtn:disabled:hover {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: rgba(34, 47, 62, 0.5);
  cursor: not-allowed;
}
.tox .tox-tree .tox-trbtn--disabled svg,
.tox .tox-tree .tox-trbtn--disabled:hover svg,
.tox .tox-tree .tox-trbtn:disabled svg,
.tox .tox-tree .tox-trbtn:disabled:hover svg {
  /* stylelint-disable-line no-descending-specificity */
  fill: rgba(34, 47, 62, 0.5);
}
.tox .tox-tree .tox-trbtn--enabled,
.tox .tox-tree .tox-trbtn--enabled:hover {
  background: #a6ccf7;
  border: 0;
  box-shadow: none;
  color: #222f3e;
}
.tox .tox-tree .tox-trbtn--enabled > *,
.tox .tox-tree .tox-trbtn--enabled:hover > * {
  transform: none;
}
.tox .tox-tree .tox-trbtn--enabled svg,
.tox .tox-tree .tox-trbtn--enabled:hover svg {
  /* stylelint-disable-line no-descending-specificity */
  fill: #222f3e;
}
.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) {
  color: #222f3e;
}
.tox .tox-tree .tox-trbtn:focus:not(.tox-trbtn--disabled) svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-trbtn:active > * {
  transform: none;
}
.tox .tox-tree .tox-trbtn--return {
  align-self: stretch;
  height: unset;
  width: 16px;
}
.tox .tox-tree .tox-trbtn--labeled {
  padding: 0 4px;
  width: unset;
}
.tox .tox-tree .tox-trbtn__vlabel {
  display: block;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: -0.025em;
  margin-bottom: 4px;
  white-space: nowrap;
}
.tox .tox-tree .tox-tree--directory {
  display: flex;
  flex-direction: column;
  /* stylelint-disable no-descending-specificity */
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label {
  font-weight: bold;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn {
  margin-left: auto;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn svg {
  fill: transparent;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn.tox-mbtn--active svg,
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-mbtn:focus svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover .tox-mbtn svg,
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:focus .tox-mbtn svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) {
  background-color: transparent;
  color: #222f3e;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label .tox-chevron {
  margin-right: 6px;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--growing) .tox-chevron,
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--shrinking) .tox-chevron {
  transition: transform 0.5s ease-in-out;
}
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--growing) .tox-chevron,
.tox .tox-tree .tox-tree--directory .tox-tree--directory__label:has(+ .tox-tree--directory__children--open) .tox-chevron {
  transform: rotate(90deg);
}
.tox .tox-tree .tox-tree--leaf__label {
  font-weight: normal;
}
.tox .tox-tree .tox-tree--leaf__label .tox-mbtn {
  margin-left: auto;
}
.tox .tox-tree .tox-tree--leaf__label .tox-mbtn svg {
  fill: transparent;
}
.tox .tox-tree .tox-tree--leaf__label .tox-mbtn.tox-mbtn--active svg,
.tox .tox-tree .tox-tree--leaf__label .tox-mbtn:focus svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-tree--leaf__label:hover .tox-mbtn svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) {
  background-color: transparent;
  color: #222f3e;
}
.tox .tox-tree .tox-tree--leaf__label:hover:has(.tox-mbtn:hover) .tox-chevron svg {
  fill: #222f3e;
}
.tox .tox-tree .tox-icon-custom-state {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.tox .tox-tree .tox-tree--directory__children {
  overflow: hidden;
  padding-left: 16px;
}
.tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--growing,
.tox .tox-tree .tox-tree--directory__children.tox-tree--directory__children--shrinking {
  transition: height 0.5s ease-in-out;
}
.tox .tox-tree .tox-trbtn.tox-tree--leaf__label {
  display: flex;
  justify-content: space-between;
}
.tox .tox-revisionhistory__pane {
  padding: 0 !important;
  /* Override the default padding of tox-view__pane */
}
.tox .tox-revisionhistory__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tox .tox-revisionhistory {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  flex: 1;
  height: 100%;
  margin-top: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}
.tox .tox-revisionhistory--align-right {
  margin-left: auto;
}
.tox .tox-revisionhistory__iframe {
  flex: 1;
}
.tox .tox-revisionhistory__sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 248px;
  width: 316px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__sidebar-title {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
  color: #222f3e;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  padding: 12px;
  z-index: 1;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions {
  background-color: #f0f0f0;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  padding: 10px 12px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions:focus {
  height: 100%;
  position: relative;
  z-index: 1;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 ;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  top: 1px;
}
@media (forced-colors: active) {
  .tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  color: #222f3e;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 12px;
  padding: 12px;
  width: 100%;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card:hover {
  box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card:focus {
  position: relative;
  z-index: 1;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card:focus::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 #006ce7;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 2px #006ce7;
  box-shadow: 0 0 0 2px #006ce7 !important;
}
@media (forced-colors: active) {
  .tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card:focus::after {
    border: 2px solid highlight;
  }
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card.tox-revisionhistory__card--selected {
  background-color: #fff5cc;
  box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
  position: relative;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card.tox-revisionhistory__card--selected::after {
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 0 2px #006ce7 #e3b82a;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 6px;
  box-shadow: 0 0 0 2px #e3b82a;
}
@media (forced-colors: active) {
  .tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card.tox-revisionhistory__card--selected::after {
    border: 2px solid highlight;
  }
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-date {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-date-label {
  font-size: 16px;
  line-height: 24px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-author {
  align-items: center;
  display: flex;
  flex: 1 0 0;
  gap: 8px;
  height: 36px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-author-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__avatar {
  border-radius: 50%;
  height: 36px;
  object-fit: cover;
  vertical-align: middle;
  width: 36px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__norevision {
  color: rgba(34, 47, 62, 0.7);
  font-size: 16px;
  line-height: 24px;
  padding: 5px 5.5px;
}
.tox .tox-revisionhistory__sidebar .tox-revisionhistory__revisions .tox-revisionhistory__card-check-icon {
  color: #222f3e;
  height: 24px;
}
.tox .tox-view-wrap,
.tox .tox-view-wrap__slot-container {
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.tox .tox-view {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}
.tox .tox-view__header {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  padding: 10px 10px 2px 10px;
  position: relative;
}
.tox .tox-view__label {
  color: #222f3e;
  font-weight: bold;
  line-height: 24px;
  padding: 4px 16px;
  text-align: center;
  white-space: nowrap;
}
.tox .tox-view__label--normal {
  font-size: 16px;
}
.tox .tox-view__label--large {
  font-size: 20px;
}
.tox .tox-view--mobile.tox-view__header,
.tox .tox-view--mobile.tox-view__toolbar {
  padding: 8px;
}
.tox .tox-view--scrolling {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tox .tox-view__toolbar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  overflow-x: auto;
  padding: 10px 10px 2px 10px;
}
.tox .tox-view__toolbar__group {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.tox .tox-view__header-start,
.tox .tox-view__header-end {
  display: flex;
}
.tox .tox-view__pane {
  height: 100%;
  padding: 8px;
  position: relative;
  width: 100%;
}
.tox .tox-view__pane_panel {
  border: 1px solid #eeeeee;
  border-radius: 6px;
}
.tox:not([dir=rtl]) .tox-view__header .tox-view__header-start > *,
.tox:not([dir=rtl]) .tox-view__header .tox-view__header-end > * {
  margin-left: 8px;
}
.tox[dir=rtl] .tox-view__header .tox-view__header-start > *,
.tox[dir=rtl] .tox-view__header .tox-view__header-end > * {
  margin-right: 8px;
}
.tox .tox-well {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 8px;
  width: 100%;
}
.tox .tox-well > *:first-child {
  margin-top: 0;
}
.tox .tox-well > *:last-child {
  margin-bottom: 0;
}
.tox .tox-well > *:only-child {
  margin: 0;
}
.tox .tox-custom-editor {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}
/* stylelint-disable */
.tox {
  /* stylelint-enable */
}
.tox .tox-dialog-loading::before {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.tox .tox-tab {
  cursor: pointer;
}
.tox .tox-dialog__content-js {
  display: flex;
  flex: 1;
}
.tox .tox-dialog__body-content .tox-collection {
  display: flex;
  flex: 1;
}
