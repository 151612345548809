.tw-browser-indicator-offline {
  @apply tw-text-orange-700;

  & > * {
    @apply tw-text-orange-700;
  }

  .tw-btn-tertiary-warning {
    @apply tw-block;
  }

  // Astuce pour éviter que l'indicateur ne se mette par dessus le texte dans l'édition d'un bien
  .pull-right {
    @apply tw-pr-12;
  }
}
